import SimpleSchema from 'simpl-schema'

export default {
  _id: {
    type: String,
    visibility: false
  },
  name: {
    label: 'Nombre',
    type: String
  },
  phone: {
    label: 'Teléfono',
    type: String
  },
  flotilla: {
    label: 'Flotilla',
    type: String
  },
  license: {
    label: 'Lincencia de conducir',
    type: String,
    optional: true
  },
  bloodType: {
    label: 'Tipo de sangre',
    type: String,
    options: [
      { id: 'A+', name: 'A+' },
      { id: 'A-', name: 'A-' },
      { id: 'B+', name: 'B+' },
      { id: 'B-', name: 'B-' },
      { id: 'AB+', name: 'AB+' },
      { id: 'AB-', name: 'AB-' },
      { id: 'O+', name: 'O+' },
      { id: 'O-', name: 'O-' },
    ],
    optional: true
  },
  emergencyContactName: {
    label: 'Contacto de emergencia',
    type: String,
    optional: true
  },
  emergencyContactPhone: {
    label: 'Teléfono de emergencia',
    type: String,
    optional: true
  },
  photo: {
    label: 'Foto <strong style="font-size: 14px;">max(1920x1080px)</strong>',
    type: SimpleSchema.Any,
    optional: true,
    image: true,
    maxWidth: 1920,
    maxHeight: 1080,
  },
  // attributes: {
  //   label: 'Atributos',
  //   type: Object,
  //   optional: true,
  //   attributes: true
  // }
};
